import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './AboutPage.css'

// Export Template for use in CMS preview
export const AboutPageTemplate = ({ title, featuredImage, phase_1, phase_2}) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      backgroundImage={featuredImage}
    />

    <section className="section About--Section1 About--Sub--Description">
      <div className="container About--Section1--Container">
		<p className="sub--description">九州史学会の歴史は、大きく1971年以前と72年以後に分かれます。</p>
	  </div>
	</section>
    <section className="section About--Section1">
      <div className="container About--Section1--Container">
        <div className="About--Section1--Title">
			<h2>第1期</h2>
        </div>
        <div>
			<Content source={phase_1} />
        </div>
      </div>
    </section>

    <section className="section About--Section1">
      <div className="container About--Section1--Container">
        <div className="About--Section1--Title">
			<h2>第2期</h2>
        </div>
        <div>
			<Content source={phase_2} />
        </div>
      </div>
    </section>
  </main>
)

// Export Default HomePage for front-end
const AboutPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <AboutPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        featuredImage
		phase_1
		phase_2
      }
    }
  }
`
